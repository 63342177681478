import React, {useEffect, useState} from 'react';
import Background from "../header/Background";
import './visit.css';
import StatusMenu from "../statusmenu/StatusMenu";
import lastScanImg from "./images/reader.png";
import ticketImg from "./images/ticket.png";
import scanVideo from "./video/scan-video.mp4";
import {useAppContext} from "../appContext";
import Spinner from "../spinner/spinner";
import {useNavigate} from "react-router-dom";
import axios from "axios";

export default function Main() {
    const { globalProps } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [waiting, setWaiting] = useState(true);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(globalProps.company == null){ navigate('/companies'); }
        if(globalProps.visit.visitor_hash == null ){ navigate('/companies'); }

        if(globalProps.action == "create"){
            createVisit();
        }else{
            updateVisit();
        }

        const timeoutId = setTimeout(() => {
            navigate("/companies")
        }, 10000);

        return () => clearTimeout(timeoutId);

    }, [])

    useEffect(() => {
        if(globalProps.company.settings.confirm_scenario == 1){
            const waitTimer = setTimeout(() => {
                setWaiting(false);
            }, 3000);

            return () => clearTimeout(waitTimer);
        }
    }, [])
    const createVisit = async () => {
        try {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/create" ,
                headers: {},
                data: globalProps.visit
            })
            .then((result) => {
                axios({
                    method: 'POST',
                    url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update" ,
                    headers: {},
                    data: {
                        visit_hash: result.data.visit,
                        status: (globalProps.company.settings.confirm_scenario == 1 ? 10 : 20)
                    }
                })
                .then((result) => {
                    setIsLoading(false)
                })
            })
        } catch (error) {
            console.error('Error sending image:', error);
        }
    };

    const updateVisit = async () => {
        axios({
            method: 'POST',
            url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update" ,
            headers: {},
            data: {
                visit_hash: globalProps.visit.visit_hash,
                status: (globalProps.company.settings.confirm_scenario == 1 ? 10 : 20)
            }
        })
        .then((result) => {
            setIsLoading(false)
        })
    }

    return (
        <>
            {isLoading ? (<Spinner />) : (
            <Background>
                <div className="wrapper">
                    {globalProps.company.settings.confirm_scenario == 1 ? (

                        <div className="finish-text-wrap whait">
                            {waiting ? (<>
                                <div className="finish-text">
                                <h2>Очікуйте підтвердження
                                    та отримайте перпустку</h2>
                                </div>
                            </>) : (<>
                                <div className="scan-steps">
                                    <div className="last-scan-step">
                                        <video className="scan-video"  muted loop autoPlay>
                                            <source src={scanVideo} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </>)}
                        </div>
                    ) : (
                        <div className="finish-text-wrap">
                            <div className="finish-text">
                                <div className="scan-steps">
                                    <div className="last-scan-step">
                                        <video className="scan-video"  muted loop autoPlay>
                                            <source src={scanVideo} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Background>
            )}
        </>
    );
}
